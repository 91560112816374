@use '../../../../../styles/mixins';

.no-results {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 85px;

  @include mixins.md {
    flex-direction: row;
    margin: 85px 50px;
    justify-content: space-around;
  }

  .left-column {
    text-align: center;

    @include mixins.md {
      flex-basis: 55%;
      text-align: left;
    }

    h2 {
      font-size: 22px;
      font-weight: 800;

      @include mixins.md {
        line-height: 50px;
        font-size: 40px;
      }
    }

    .p {
      font-size: 16px;

      @include mixins.md {
        line-height: 20px;
        font-size: 18px;
      }
    }

    .button-blue {
      width: 160px;
      margin-top: 15px;

      @include mixins.md {
        margin-top: 40px;
      }
    }
  }

  img {
    width: 70%;
    margin-top: 30px;

    @include mixins.md {
      flex-basis: 45%;
      margin-top: 0;
      margin-left: 85px;
    }
  }
}
