@use '../../../styles/colors';

.modal-dialog {
  .dialog-header {
    text-transform: uppercase;
    font-size: 16px;
    padding-bottom: 40px;
  }

  .dialog-subtitle {
    text-align: center;
    margin-bottom: 2rem;
  }

  .dialog-input-wrapper {
    display: flex;
    flex-direction: row;
    padding-bottom: 26px;
    align-self: center;
    width: 86%;
    gap: 0.5rem;

    & .MuiInputBase-input {
      padding: 16.5px 14px;
    }
  }

  .dialog-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
  }

  .user-input-container {
    display: flex;
    flex-direction: column;
    width: 96%;
  }

  .MuiDialog-paperWidthSm {
    max-width: 100%;
  }

  .dialog-wrapper {
    display: flex;
    flex-direction: column;
    min-width: 550px;
    align-items: center;
    padding: 15px 40px 25px 40px;
    overflow-y: auto;
    overflow-x: hidden;
    max-width: 600px;

    &.dialog-border {
      border: 4px solid colors.$blue;
    }

    .dialog-close {
      align-self: flex-end;
      color: colors.$gray;
      cursor: pointer;
    }

    .button-red,
    .button-blue {
      max-width: 200px;
      margin-top: 30px;
    }

    .dialog-date {
      align-self: flex-start;
    }
  }

  .add-line-modal-button {
    font-size: 1rem;
    color: colors.$blue;
  }

  .line-text-box {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-bottom: 26px;

    & .MuiInputBase-input {
      padding: 16.5px 14px;
    }
  }

  .space-holder {
    min-width: 1rem;
  }

  .trash-can-button {
    padding-top: 1rem;

    svg {
      color: colors.$red;
    }
  }
}
