@use '../../styles/colors';

.modal-container,
.modal-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.modal-container {
  display: flex;
  flex-direction: column;
  overflow: scroll;

  z-index: 3;

  &[aria-hidden='true'] {
    display: none;
  }

  .modal-overlay {
    background-color: colors.$overlay-transparent-black;
  }

  .modal-content {
    display: flex;
    flex-direction: column;
    max-width: 600px;
    align-items: center;
    padding: 40px;
    overflow-y: auto;
    overflow-x: hidden;
    margin: auto 0.5rem;

    border: 4px solid #097bbc;
    background: colors.$white;
    color: colors.$black;

    font-family: 'Open Sans';
    font-weight: normal;
    font-size: 1rem;

    z-index: 2;
    position: relative;

    @media (min-width: 768px) {
      min-width: 550px;
      margin: auto;
      width: unset;
      height: unset;
    }

    .modal-title {
      display: none;
    }

    .modal-close-button {
      margin: 0.8rem 1.5rem 0 0;
      color: colors.$icon-gray;
      font-size: 1rem;

      position: absolute;
      right: 0;
      top: 0;
      z-index: 1;
    }
  }
}
