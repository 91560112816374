.editOverviewModal {
  width: 100%;
  text-align: center;

  .title {
    text-transform: uppercase;
    font-weight: bold;
  }

  .inputFields {
    max-width: 24rem;
    margin: 2rem auto;

    .textField {
      margin-top: 1rem;
      min-height: 3rem;
    }
  }

  .buttonContainer {
    display: flex;
    gap: 1rem;
    justify-content: center;
  }
}
