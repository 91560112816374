@use './../../../../../../styles/colors.scss';
@use './../../../../../../styles/mixins';

.update-box-wrapper {
  background-color: colors.$white;
  padding: 25px;
  margin-bottom: 25px;
  border-radius: 10px;
  max-width: 100%;

  @include mixins.lg {
    display: flex;
    flex: 0 0 49%;
    flex-direction: column;
    max-width: 49%;
  }
  &:last-child {
    flex: 1 1 100%;
    max-width: 100%;
    margin-bottom: 0;
    .update-box-content {
      img {
        max-width: 100%;
      }
    }
    .update-box-content,
    .update-box-header-flex {
      max-width: 100%;
    }
  }
  .update-box-header-flex {
    max-width: 560px;
    display: flex;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    align-items: center;
    padding: 0;
  }
  .update-box-title-flex {
    align-items: center;
    padding: 10px 16px 10px 0;
    img {
      padding-right: 15px;
    }
    &.whatsnew,
    &.whatsnext,
    &.objectivesandkeyresults,
    &.additionalnotes {
      color: colors.$footer;
    }
    &.risksblockers,
    &.helpneeded {
      color: colors.$red;
    }
    &.braggingpoints,
    &.upcomingdates {
      color: colors.$green;
    }
    &.performancetoobjectives {
      color: colors.$black;
    }
    &.additionalnotes,
    &.objectivesandkeyresults {
      img {
        display: none;
      }
    }
  }
  .update-box-content {
    display: block;
    padding: 20px 0 0 0;
    overflow-x: auto;

    @include mixins.tinyMceContentBulletFix;
  }
}
