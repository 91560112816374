.product-hierarchy {
  position: relative;
  width: 80%;
  display: flex;
  justify-content: center;
  flex-direction: column;

  .product-hierarchy-select {
    padding-bottom: 26px;
    min-width: 80%;
  }

  &.group-select {
    align-self: center;
    padding: 0.625rem;

    .product-hierarchy-select {
      padding: unset;
    }
  }
}
