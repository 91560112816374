input,
textarea {
  border: 1px solid #e1e1e1;
  padding: 10px;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  transition: all 0.3s linear;
  border-radius: 3px;
}

input {
  height: 40px;
  margin: 3px 0;
  outline: none;
  line-height: normal;
  font-size: 14px;
}

input[type='submit'] {
  cursor: pointer;
  border-style: solid;
  border-width: 2px;
  padding-top: 0;
  padding-bottom: 0;
}

select {
  border: 1px solid #e1e1e1;
  height: 40px;
  padding: 5px;
}

input:focus,
textarea:focus {
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
}

*:focus {
  outline: none;
}
