.tab-content-container {
  background-color: $background-gray;
  padding: 25px;
  height: 100%;
}

.product-container-flex {
  margin-top: 45px;
  @include md {
    display: flex;
    flex-direction: row;
  }
  .product-content-left {
    margin-right: 20px;
    width: 250px;
  }
  .product-content-right {
    width: 100%;
  }

  .team-members {
    display: block;
    .team-member-list {
      padding-top: 10px;
      padding-left: 0;
      li {
        margin-bottom: 15px;
        cursor: pointer;
      }
    }
  }

  .relevant-links {
    display: block;
    margin-bottom: 20px;
    .relevant-links-container {
      padding-top: 10px;
    }
    .report-sidebar-content {
      padding-bottom: 10px;
      a {
        box-sizing: border-box;
        padding: 2px 0 2px 0;
      }
    }
  }

  .react-tabs__tab {
    width: 200px;
    padding: 15px 0;
    text-align: center;
  }
  .react-tabs__tab:focus::after {
    background: $background-gray;
  }
  .react-tabs__tab-list {
    margin: 0;
    border: none;
  }
  .react-tabs__tab--selected {
    background-color: $background-gray;
    border: none;
    font-weight: bold;
  }
  .react-tabs__tab-panel {
    height: 100%;
  }
}

.tab-panel {
  background-color: $background-gray;
}

.edit-icon {
  color: $icon-gray;
}

.relevant-links-header-container,
.team-members-header-container {
  display: flex;
  flex-direction: row;
  button {
    margin-left: 12px;
    padding: 2px 4px;
  }
}

table td {
  padding: 0.4rem;
}
