@use '../../../styles/colors';
@use '../../../styles/mixins';

.org-page-content {
  .kanban-header {
    margin: 0 20px 30px;
    overflow: auto;

    @include mixins.md {
      margin: 0;
    }

    .org-icon {
      padding: 30px 0 5px 0;
      color: colors.$blue;
    }

    .org-title {
      padding: unset;

      @include mixins.md {
        padding-bottom: 0;
        margin-bottom: unset;
      }
    }

    .org-subtitle {
      margin-bottom: 40px;
    }

    .button-blue {
      float: right;
    }

    @include mixins.md {
      text-align: unset;
    }
  }
}
