.archive-modal {
  .archive-confirmation-text {
    text-align: center;
  }

  .archive-confirmation-button-container {
    .button-blue,
    .button-red {
      width: 169px;
      margin-left: 13px;
      margin-right: 13px;
    }
  }
}
