$white: #fff;
$black: #111;
$gray: #4d4d4d;
$light-gray: #e2e2e2;
$label-gray: #757575;
$background-gray: #f6f6f6;
$disabled-gray: #72727f;
$icon-gray: #72727f;
$footer: #36364b;
$blue: #0976b5;
$light-blue: #c4d5df;
$focus-blue: #74a9e7;
$hover-blue: #034870;
$red: #c72020;
$light-red: #fee8e8;
$dark-red: #b60b0b;
$disabled-red: #e1c8c8;
$yellow: #d2b356;
$light-yellow: #efe7cd;
$green: #5e8033;
$light-green: #dfe6d6;
$storyjam-banner: #457d7d;

$overlay-transparent-black: #111111c7;
