@use './../../../../../styles/colors';
@use './../../../../../styles/mixins';

.update-controls {
  display: flex;
  justify-content: space-between;
}

.update-container-flex {
  margin-top: 30px;
  @include mixins.lg {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

.confirmation-button-container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;

  #update-edit-continue,
  #update-edit-cancel {
    max-width: 237px;
    width: 100%;
  }

  #update-edit-cancel {
    margin-top: 15px;
  }
}

.update-box-icon {
  font-size: 1.5em;
  vertical-align: bottom;
  margin-right: 15px;
}
