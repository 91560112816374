// Small devices
@mixin sm {
  @media (min-width: 375px) {
    @content;
  }
}

// Medium devices
@mixin md {
  @media (min-width: 1060px) {
    @content;
  }
}

// Large devices
@mixin lg {
  @media (min-width: 1280px) {
    @content;
  }
}

@mixin xl {
  @media (min-width: 1680px) {
    @content;
  }
}

@mixin xxl {
  @media (min-width: 1921px) {
    @content;
  }
}

@mixin tinyMceContentBulletFix {
  // These ul styles are intentional. See https://github.ford.com/FordLabs/Newsie/pull/1395
  //   and related issue for more info.
  & ul {
    list-style-type: disc;
  }

  & ul ul {
    list-style-type: circle;
  }

  & ul ul ul {
    list-style-type: square;
  }
}
