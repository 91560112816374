@use '../../styles/colors';
@use '../../styles/mixins';

@mixin display-flex {
  display: flex;
  flex-wrap: wrap;
}

.footer {
  background: colors.$footer;
  position: absolute;
  height: fit-content;
  width: 100%;
  letter-spacing: 0.1em;

  a {
    text-decoration: underline;
    color: colors.$white;
  }

  .footer-content {
    @include display-flex;
    width: 100%;
    padding: 0 50px;
    flex-direction: row;
    justify-content: center;
    color: colors.$white;

    .icon {
      margin-right: 10px;
      color: colors.$white;
    }

    .logo-icon {
      margin-right: 32px;
    }

    h4,
    h5 {
      color: colors.$white;
    }

    @include mixins.md {
      justify-content: space-between;
      align-items: center;
    }

    @include mixins.xxl {
      width: 1920px;
    }
  }

  .top-footer-section,
  .bottom-footer-section {
    @include display-flex;

    justify-content: space-between;
    margin-top: 32px;
    padding-bottom: 32px;

    @include mixins.md {
      width: 100%;
    }
  }

  .top-footer-section {
    border-bottom: 1px solid #757575;

    h4 {
      padding-bottom: 15px;
      font-size: 18px;
      font-weight: 700;
    }
  }

  .explore-section,
  .powered-by-section > * {
    margin-bottom: 45px;

    @include mixins.md {
      margin: 0;
    }
  }

  .explore-section {
    @include mixins.sm {
      margin-right: 10px;
    }

    .button-outline {
      padding: 5px 15px;
    }
  }

  .powered-by-section,
  .CIA-rating-section {
    @include display-flex;
    align-items: flex-end;
  }

  .CIA-rating-section {
    max-width: 466px;

    p {
      font-size: 11px;
      line-height: 14px;
    }
  }

  .powered-by {
    a:hover {
      color: white;
    }
  }

  .help-section {
    .icons-group {
      @include display-flex;
      flex-direction: row;
      align-items: center;

      img {
        width: 25px;
      }

      a {
        @include display-flex;
        padding: 10px;
        align-items: center;
      }
    }
  }
}
