@use '../../../../../../styles/colors';
@use '../../../../../../styles/mixins';

.content {
  width: 100%;
  text-align: center;

  .textField {
    max-width: 23.419rem;
    width: 100%;
    font: inherit;
  }

  .addAnotherLineBtn {
    font-size: 1rem;
    color: colors.$blue;
    margin-block: 1rem;
    padding: 1rem;
  }

  .lineFieldContainer {
    position: relative;

    max-width: 27rem;
    margin: 1.5rem auto 0;

    .trashCanButton {
      position: absolute;
      right: 0;
      top: 0.5rem;
      color: colors.$red;
      padding: 0.5rem;

      &:hover {
        color: colors.$dark-red;
      }
    }
  }
}
