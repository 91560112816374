@use '../../styles/colors';
@import '../../styles/base.scss';

.content-container.product-nav {
  min-height: 40px;
  flex: none;
  background-color: white;
  margin: 15px 0;

  a {
    padding: 8px;
  }
}

.nav-button {
  @extend button;

  height: 32px;
  width: 32px;
  border-radius: 50%;
  background-color: white;

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;

    &:focus {
      border: none;
    }
  }

  &:hover {
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  }
}

.product-nav,
.nav-button {
  .product-nav-container {
    width: 40%;
    margin: auto;
  }

  a {
    color: colors.$blue !important;

    &:hover {
      color: colors.$hover-blue !important;
    }

    &:first-child {
      float: left;
    }

    &:last-child {
      float: right;
    }
  }

  .product-nav-arrow {
    width: 32px;
  }
}

.product-nav-container {
  display: flex;
  justify-content: space-between;
  column-gap: 1rem;
}
