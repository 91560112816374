@use '../../../../styles/mixins';

.kanban-actions {
  display: flex;
  justify-content: space-between;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  z-index: 1;
  padding: 1.5rem;
  border-bottom: 1px solid rgba(196, 196, 196, 0.5);

  @include mixins.md {
    align-items: center;
  }

  @media only screen and (max-width: 1059px) {
    flex-direction: column;
    gap: 1rem;
  }

  .archived-products-link {
    text-decoration: underline;
  }

  .filter {
    gap: 1.5rem;
    display: flex;
    align-items: center;

    @media only screen and (max-width: 1059px) {
      flex-direction: column;
      align-items: unset;
    }
  }
}
