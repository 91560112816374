@use '../../../../../../../../styles/colors';

.add-product-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;

  .add-product-stage-select {
    min-width: 80%;
    padding-bottom: 26px;
  }

  .add-product-section-title {
    text-align: center;
  }

  .add-new-product-section,
  .add-team-member-section {
    border: 1px solid transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    position: relative;

    .MuiOutlinedInput-input {
      display: flex;
    }

    .remove-team-member-button {
      color: colors.$gray;
      position: absolute;
      top: 0;
      right: 10px;
      font-size: 20px;
      font-weight: 100;
      padding: 5px;
    }

    &.close-button-hover {
      border: 1px solid #097bbc;
      border-radius: 15px;
      background: rgba(9, 123, 188, 0.1);
    }

    .text-field-with-tooltip {
      position: relative;
    }
  }
}
