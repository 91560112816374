@use './../../../../../../styles/colors';

.update-buttons {
  display: flex;
  flex-direction: row;

  .write-update-button {
    color: colors.$icon-gray;
    margin: 0 15px;
    padding: 0 5px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;

    .fai-shadow {
      height: 24px;
    }

    .plus {
      width: 24px;
    }

    .pen {
      width: 21px;
    }

    span {
      color: black;
      font-size: 12px;
      padding-top: 10px;
    }
  }

  .write-update-button:hover {
    text-decoration-color: black;

    .fai-shadow {
      filter: drop-shadow(0px 4px 4px #00000040);
    }
  }
}

.edit-update-buttons {
  margin: auto 0 auto auto;

  button {
    width: 144px;
  }

  .button-red {
    margin-left: 24px;
  }
}
