.product-header {
  background-color: $blue;
  z-index: 2;
  padding-top: 16px;
  @include md {
    padding: 20px 0;
  }
  .header-content {
    padding: 0 50px;
  }
  .header-container {
    display: flex;
    justify-content: space-between;
  }
  a {
    text-decoration: underline;
    &:hover {
      font-weight: bold;
    }
  }

  h3,
  h6,
  a {
    color: $white;
    font-weight: 600;
  }
  h3 {
    padding-top: 5px;
    font-weight: bold;
    @include md {
      padding-top: 0;
      margin-right: 45px;
    }
  }
}
