@use '../../../styles/colors';
@use '../../../styles/mixins';

.archived-products-title {
  background-color: colors.$blue;
  height: 105px;
  min-width: 100%;

  h1 {
    color: colors.$white;
    font-weight: 700;
    line-height: 18px;
  }

  .done-text-title {
    font-size: 26px;
    padding: 12px 0 24px 0;
  }

  .org-title {
    font-size: 13px;
    padding-top: 0;

    a {
      color: colors.$white;
    }
  }
}

.product-groups {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  @include mixins.md {
    flex-direction: row;
  }

  .product-group-content {
    padding: 30px 0;

    &:first-of-type {
      padding-top: 45px;

      @include mixins.md {
        padding-top: 30px;
      }
    }

    @include mixins.md {
      padding-right: 30px;
      width: 33%;
    }

    @include mixins.xl {
      width: 25%;
    }
  }

  .product-group-name {
    font-weight: 700;
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    width: 100%;
  }

  .product-name {
    display: flex;
    flex-direction: column;
    padding: 0 0 10px 16px;
  }

  .archived-product-line {
    padding: 16px 0;
    font-weight: 600;
  }
}
