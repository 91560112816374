@use 'fonts';
@use 'colors';
@use 'mixins';

/*------------------------------------------------------------------------------------------*/
/* 1. Defaults & Reset of specific styles across browsers */
/*------------------------------------------------------------------------------------------*/
*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  height: 100%;
}

body,
div,
dl,
dt,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
blockquote,
th,
td {
  margin: 0;
  padding: 0;
  direction: ltr;
}

ul {
  list-style-type: none;
}

.with-bullets {
  list-style: disc;
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden;
  min-height: 100vh;
  font-family: fonts.$font-families;
  font-size: 15px;
  font-weight: normal;
  color: #111;
}

h1 {
  font-size: 40px;
  font-weight: 300;
  color: colors.$black;
}

h2 {
  font-size: 34px;
  font-weight: 300;
  color: colors.$black;
}

h3 {
  font-size: 26px;
  font-weight: 700;
  color: colors.$black;
}

h4 {
  font-size: 24px;
  font-weight: 700;
  color: colors.$black;
}

h5 {
  font-size: 16px;
  font-weight: 400;
}

h6 {
  font-size: 14px;
  font-weight: 600;
  color: colors.$gray;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

p {
  line-height: 25px;

  a {
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
  }
}

.row img {
  height: auto;
  max-width: 100%;
}

.no-padding {
  padding: unset;
}

.no-margin {
  margin: unset;
}

.italic {
  font-style: italic;
}

button {
  color: colors.$blue;

  &:hover {
    text-decoration: underline;
  }

  &.active {
    color: colors.$blue;
    text-decoration: underline;
  }

  border: none;
  background: transparent;
  padding: 0;
  font-size: 15px;
  cursor: pointer;
  font-family: inherit;
}

.icon {
  color: colors.$blue;
}

.fa-2x {
  font-size: 2em;
}

.fa-1-5x {
  font-size: 1.5em;
}

.content-container {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  flex: auto;
  position: relative;
  padding: 0 20px;

  @include mixins.md {
    padding-left: 50px;
  }

  @include mixins.xxl {
    width: 1920px;
  }
}

.caps-header {
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.1em;
  color: colors.$black;
  text-transform: uppercase;
}

iframe {
  border: 0 !important;
}

#wrapper {
  min-height: calc(100vh - 260px);
  height: 100%;
  overflow: visible;

  @include mixins.md {
    min-height: calc(100vh - 100px);
  }
}

#main-content {
  // min-height: calc(100vh - height of footer - height of header, height of nav bar);
  min-height: calc(100vh - 260px - 90.78px);
  flex-direction: column;
  justify-content: space-between;
  background-color: colors.$white;

  @include mixins.md {
    min-height: calc(100vh - 100px - 112.25px);
  }
}

.nav-header {
  position: relative;
  background-color: colors.$white;
  z-index: 3;
}

.sticky {
  background-color: colors.$white;
  top: -1px;
  position: sticky;
}

.light {
  font-weight: 300;
}

.semi-bold {
  font-weight: 600;
}

.bold {
  font-weight: 700;
}

.extra-bold {
  font-weight: 800;
}

@keyframes fadeIn {
  from {
    opacity: 0.25;
  }
  to {
    opacity: 1;
  }
}

.center {
  height: 100%;
  padding-top: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.loading-icon {
  font-size: 80px;
  animation: fadeIn 1.5s infinite alternate;
}

.icon-loading {
  .center {
    padding-top: 0;
  }

  .loading-icon {
    font-size: 40px;
  }
}

.skip-link {
  position: absolute;
  transform: translateY(-1000px);

  &:focus {
    position: relative;
    transform: translateY(0px);
  }
}

.button-blue {
  background-color: colors.$blue;
  padding: 5px 13px;
  text-transform: uppercase;
  color: colors.$white;
  font-weight: bold;
  height: 2.63rem;

  a {
    text-decoration: unset;
  }

  svg {
    margin-left: 0.5rem;
  }

  &:focus {
    box-shadow: 0 0 6px 3px colors.$focus-blue;
  }

  &:hover {
    background-color: colors.$hover-blue;
    color: colors.$white;
    text-decoration: none;
  }

  &.disabled {
    background-color: colors.$light-blue;

    &:hover {
      text-decoration: none;
      cursor: auto;
    }
  }
}

.button-outline {
  @extend button;
  @extend .button-blue;
  font-size: 15px;
  background-color: transparent;
  color: colors.$white;
  border: 3px solid colors.$white;

  &:hover {
    background-color: colors.$white;
    color: colors.$footer;
  }
}

.button-white {
  @extend .button-blue;
  color: colors.$blue;
  background-color: white;
  border: 3px solid colors.$blue;

  &:hover,
  &:active,
  &:focus {
    background-color: white;
    color: colors.$blue;
  }
}

.button-red {
  @extend .button-blue;
  color: colors.$red;
  background-color: colors.$white;
  border: solid 0.19rem colors.$red;

  &:hover {
    color: colors.$white;
    background-color: colors.$dark-red;
  }

  &.disabled {
    background-color: colors.$disabled-red;
    pointer-events: none;
  }
}

.button-blue-dialog {
  @extend .button-blue;
  color: colors.$blue;
  background-color: colors.$white;
  border: solid 0.19rem colors.$blue;

  &:hover {
    color: colors.$white;
    background-color: colors.$blue;
  }
}

.desktop-only {
  display: none;

  @include mixins.md {
    display: block;
  }
}

.mobile-only {
  display: block;

  @include mixins.md {
    display: none;
  }
}

.icon-grey {
  color: colors.$icon-gray;
}

.icon-white {
  color: colors.$white;
}

.icon-grey:hover {
  filter: drop-shadow(0px 4px 4px #00000090);
}

.MuiTooltip-arrow {
  left: 20px !important;
}

.base-link {
  font-style: normal;
  font-size: 15px;
  line-height: 20px;
  text-decoration-line: underline;
}

.standard-link,
a {
  @extend .base-link;
  font-weight: 400;
  color: #0976b5;

  &:hover,
  &:active {
    color: #034870;
  }
}

.heading-with-pencil {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.heading-link {
  @extend .base-link;
  font-weight: 700;
  color: #0976b5;
  text-transform: uppercase;

  &:hover,
  &:active {
    color: #034870;
  }
}

.breadcrumb-link {
  @extend .base-link;
  font-weight: 600;
  color: colors.$white;
  text-transform: uppercase;

  &:hover,
  &:active {
    color: colors.$white;
    font-weight: 700;
  }
}

.footer-link {
  @extend .base-link;
  font-weight: 600;
  color: colors.$white;

  &:hover,
  &:active {
    color: colors.$white;
    font-weight: 700;
  }
}

//TODO Should be moved into GroupAndLines styles
.product-count {
  color: colors.$label-gray;
  font-size: 12px;
}

.gray-out-select {
  background-color: colors.$light-gray;
}
