@use '../../../../styles/colors';

#modal-view-item {
  width: 100%;
  border-top: 1px solid colors.$light-gray;

  ul {
    width: 100%;
    padding-left: 0;
    margin: 0;
  }

  .list-item {
    display: flex;
    padding: 8px;
    justify-content: space-between;
    border-bottom: 1px solid colors.$light-gray;

    div {
      width: 100%;
      display: flex;
    }

    .data-name {
      width: 100%;
    }

    svg {
      color: colors.$blue;
    }

    .delete-item-button {
      svg {
        color: colors.$red;
      }
    }

    .edit-item-button,
    .delete-item-button,
    .arrange-item-button {
      font-size: 20px;
      cursor: move;
    }

    .edit-item-button,
    .delete-item-button {
      margin-right: 25px;
    }
  }

  .add-items-button {
    margin-top: 30px;
    border: none;
    color: colors.$blue;
    text-transform: uppercase;

    svg {
      margin-right: 10px;
    }
    &:focus {
      text-decoration: underline;
    }
  }
  .save-team-member {
    display: block;
    width: 170px;
    margin: 40px auto auto;
  }
}

.back-to-team-links {
  align-self: start;
  color: colors.$blue;

  svg {
    margin-right: 0.25rem;
  }
}

#edit-team-modal-delete-item {
  .edit-team-delete-button-container {
    display: flex;
    justify-content: space-around;
  }
}
