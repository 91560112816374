@use 'colors';

h1 {
  padding: 20px 0;
}

h2 {
  padding: 14px 0;
}

h3 {
  padding: 10px 0;
}

h4 {
  padding: 7px 0;
}

h5 {
  padding: 7px 0;
}

h6 {
  padding: 7px 0;
}

/* Text Alignment */

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

/* Section Headings */

.section-heading {
  padding: 0 0 15px 0;
}

.org-title {
  padding: 30px 0 0 0;
  font-size: 30px;
  font-weight: 700;
}

h3.listing-heading {
  font-size: 24px;
  font-weight: 700;
  color: #111;
  letter-spacing: 0;
}

$font-families: 'Open Sans', sans-serif, Arial, Helvetica;
