@use '../../styles/fonts';
@use '../../styles/colors';
@use '../../styles/mixins';

.org-details {
  button {
    margin: 2.5rem;
  }

  .org-details-container {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    margin: 1.25rem;

    @include mixins.md {
      margin: 2.5rem 15rem 2.5rem 2.5rem;
    }
  }

  .details-box {
    border-radius: 10px;
    max-width: 70rem;

    background-color: white;
    box-shadow: 0 10px 23px rgba(0, 0, 0, 0.2);
    text-decoration: none;
    text-align: left;

    padding: 1rem 2.5rem 1.5rem 2.5rem;

    .box-info {
      line-height: 1.75rem;
    }

    &.edit-mode {
      .box-header {
        justify-content: space-between;
      }
    }

    &:not(&.edit-mode) {
      .box-info {
        & .MuiPaper-root:first-child {
          border: none;
        }
      }

      .lines {
        border: none;
        padding: 0.25rem 10rem 0.25rem 3rem;
      }
    }

    .box-header {
      font-family: fonts.$font-families;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0.1em;
      padding: 1.5rem 0 1rem;
      text-transform: uppercase;
      color: #111111;
      display: flex;
      flex-direction: row;

      .edit-icon {
        margin: 0 0 0 0.6rem;
        box-shadow: none;
      }
    }

    .lines {
      border-bottom: 1px solid #e6e6e6;
      display: flex;
      flex-grow: 0;
      justify-content: space-between;
      flex-direction: row;
      padding: 0.75rem 11.8rem 0.75rem 4rem;
    }
  }

  .box-info {
    display: flex;
    flex-direction: column;
    font-family: 'Open Sans';
    font-style: normal;
    font-size: 15px;
    line-height: 1.5rem;
    margin-right: 2.5rem;

    color: #111111;

    & .MuiPaper-root:first-child {
      border-top: 1px solid #e6e6e6;
      border-radius: 0;
    }

    .tool-bar {
      min-width: 8rem;
      display: flex;
      flex-direction: row;
    }

    .trash-icon {
      width: 2rem;
      height: 2rem;
      color: colors.$red;
      margin: unset;

      & svg:hover {
        filter: drop-shadow(0px 4px 4px #00000090);
      }
    }

    .button-add {
      border: none;
      color: colors.$blue;
      margin: 1rem 0 0 1.5rem;
      text-transform: uppercase;
      align-self: flex-start;

      svg {
        margin-right: 10px;
      }

      &:focus {
        text-decoration: underline;
      }
    }

    .add-line-button {
      padding: 0.25rem 10rem 1rem 2.5rem;
      border-bottom: 1px solid #e6e6e6;
    }

    .edit-pencil {
      width: 2rem;
      height: 2rem;
      color: colors.$blue;
      margin: unset;

      & svg:hover {
        filter: drop-shadow(0px 4px 4px #00000090);
      }
    }

    .button-blue {
      width: 5rem;
      align-self: center;
    }
  }
}
