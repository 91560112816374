@use '../../styles/colors';

.textFieldTooltip {
  position: absolute;
  right: -50px;
  top: 0;

  padding: 16px;
  color: colors.$light-gray;
  cursor: pointer;
  font-size: 20px;

  &:hover {
    color: colors.$footer;
  }
}
