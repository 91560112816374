@import '../../../styles/mixins';
@import '../../../styles/colors';
$min-width: 603px;
$mid-width: 1360px;

.banner {
  align-items: center;
  color: white;
  background-color: $storyjam-banner;
  width: 100%;
  height: auto;
  font-family: inherit;
  font-style: normal;
  font-weight: 800;
  padding: 10px 0;
  margin-bottom: 130px;
  display: none;

  @media only screen and (min-width: $min-width) {
    height: 140px;
    padding: 0;
  }

  @media only screen and (min-width: 770px) {
    margin-bottom: 60px;
  }

  .banner-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    margin: 0 auto;
    padding: 0 5rem;
    justify-content: center;

    .banner-text-container {
      display: flex;
      flex-direction: column;
      grid-area: text-box;
      text-align: left;
      max-width: 45rem;
      font-family: 'Luckiest Guy', sans-serif, Arial, Helvetica;
      letter-spacing: 0.1em;
      font-weight: 400;
      text-transform: uppercase;
      margin: 0 7rem;
      @media only screen and (max-width: $mid-width) {
        margin: 0 2.5rem;
      }
    }

    .banner-text-1 {
      font-size: 16px;
      line-height: 24px;
    }

    .banner-text-2 {
      font-size: 24px;
      line-height: 30px;
    }

    .try-link {
      font-family: 'Luckiest Guy', sans-serif, Arial, Helvetica;
      font-weight: 400;
      font-size: 20px;
      line-height: 22px;
      letter-spacing: 0.1em;
      color: white;
      padding: 0 60px;
      height: 53px;
      background: #aa35aa;
      border: 3px solid #ecc5ec;
      box-shadow: 1px 3px 7px 3px rgba(0, 0, 0, 0.25);
      span {
        display: inline-block;
        padding: 14px 0 0;
      }

      &:hover,
      &:focus {
        text-decoration: none;
      }
      @media only screen and (min-width: $min-width) {
        margin: unset;
      }
    }
  }
  @include md {
    display: flex;
  }
}
