.title-text {
  @include md {
    p {
      padding-bottom: 50px;
    }
  }
}

.title-image {
  width: 218px;
  height: 153px;

  @include md {
    width: 400px;
    height: 281.52px;
    align-self: center;
    margin-right: 2rem;
  }
}

.browse-all-orgs {
  background-color: $white;
}

.org-card {
  svg {
    display: block;
  }
}

#all-orgs {
  p {
    display: block;
  }
}

#all-orgs-link {
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  padding: 10px;
}

#all-orgs-link-arrow {
  font-size: 20px;
  vertical-align: middle;
  margin-left: 6px;
}

.all-orgs-button {
  margin: -2rem auto 2rem auto;
  padding: 13px 24px;
  width: fit-content;
}
