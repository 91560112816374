@use './../../../../../../styles/colors.scss';

.no-update-message-container {
  align-items: center;
  flex: auto;
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  color: colors.$gray;
  h4 {
    color: colors.$gray;
    font-size: 26px;
    margin-top: 45px;
    padding: 0;
  }
  div {
    text-align: center;
  }
  p {
    font-size: 16px;
    max-width: 430px;
    margin-bottom: 35px;
  }
}
