@use '../../styles/mixins';
@use '../../styles/colors';

.nav-container {
  align-items: center;
  margin: auto;
  text-transform: uppercase;
  display: flex;
  justify-content: space-between;
  min-height: 60px;
  padding: 20px 20px;
  box-shadow: 0 10px 23px rgba(0, 0, 0, 0.05);

  @include mixins.xxl {
    width: 1920px;
  }

  .button-blue {
    font-size: 18px;
    letter-spacing: 0.03em;
  }

  .button-clear {
    padding: 10px 15px;
    font-weight: bold;
    color: colors.$blue;
    font-size: 18px;
    letter-spacing: 0.03em;
    border: none;
    margin-right: 20px;

    svg {
      margin-left: 0.5rem;
    }
  }

  .nav-title {
    margin: 0;
    font-size: 26px;
    font-weight: 800;
    flex: 1 0 20%;

    img {
      max-width: 150px;
    }
  }

  .nav-hamburger {
    color: colors.$green;
    font-size: 24px;
  }

  @include mixins.md {
    padding: 20px 50px;
  }
}

.nav-dropdown-container {
  width: 100%;
  background-color: white;
  position: absolute;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-top: 1px solid colors.$light-gray;
  max-height: 75vh;

  .nav-dropdown-content {
    margin: 30px auto;
    display: grid;
    grid-template-columns: auto;
    justify-content: left;

    .nav-link {
      width: 350px;
      cursor: pointer;
      padding: 10px;
      border-radius: 10px;
      text-decoration: none;
      display: grid;
      grid-template-columns: auto auto;
      justify-content: start;
      margin-bottom: 30px;

      &:hover {
        background-color: colors.$light-blue;
        font-weight: 700;
      }

      .nav-link-icon {
        font-size: 26px;
        color: colors.$blue;
        width: 40px;
        margin: 0 auto;
      }

      .nav-link-text {
        font-size: 18px;
        color: black;
        margin-left: 12px;
      }

      @include mixins.md {
        margin-bottom: 0;
      }
    }

    @include mixins.md {
      grid-template-columns: auto auto auto;
      justify-content: center;
      max-width: 1250px;
    }
  }

  @include mixins.md {
    max-height: 80vh;
  }
}

#nav-dropdown-overlay {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  position: absolute;
  z-index: -1;

  @include mixins.md {
    display: none;
  }
}
