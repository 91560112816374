.stage-progress-bar {
  width: 100%;
  z-index: 1;

  ul {
    list-style-type: none;
    display: flex;

    @media only screen and (max-width: 770px) {
      margin-bottom: unset;
      padding: unset;
    }

    li {
      float: left;
      width: 20%;
      min-width: 10%;
      position: relative;
      text-align: center;
      padding: 9px;

      &:before {
        content: '';
        counter-increment: step;
        width: 15px;
        height: 15px;
        display: block;
        margin: 0 auto 10px auto;
        border-radius: 50%;
        line-height: 27px;
        background: #e6e6e6;
        color: #e6e6e6;
        text-align: center;
        font-weight: bold;
      }

      &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 3px;
        background: #e6e6e6;
        top: 15px;
        left: -50%;
        z-index: -1;
      }

      &:first-child:after {
        content: none;
      }

      &.active::before {
        background-color: #5e8033;
        color: white;
      }

      &.active::after {
        background: #5e8033;
      }

      &.lastActiveClass:before {
        content: '';
        border: 2px solid #5e8033;
        background-size: 20px 20px;
        background-color: white !important;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url('../../../../styles/check-icon.svg');
        width: 25px;
        height: 25px;
        padding: unset;
        margin-top: -6px;
      }

      span {
        white-space: break-space;
        display: inline-block;
        word-break: break-word;

        @media only screen and (max-width: 770px) {
          display: none;
        }
      }

      &:not(.lastActiveClass) span {
        margin-top: 4px;
      }
    }
  }
}
