@use '../../../../../styles/colors';

.search-bar {
  width: 280px;
  .search-icon {
    color: colors.$blue;
  }
  input:focus {
    box-shadow: none;
  }
}
.filter-dropdown-header {
  width: 100%;
  padding: 6px 0;
  border-bottom: 1px solid colors.$light-gray;
}
