@use '../../../styles/colors';

.mobile-product-hierarchy {
  margin-bottom: 85px;

  .product-group-name {
    font-size: 18px;
    text-align: center;
    text-transform: uppercase;
    margin: 64px 0 32px 0;

    &:first-child {
      margin-top: 48px;
    }
  }

  .product-line-name {
    font-weight: 700;
    font-size: 14px;
  }

  .product-name {
    display: block;
    font-size: 14px;
    margin-bottom: 16px;
  }

  ul.product-list li::marker {
    color: colors.$blue;
  }
}
