@use '../../styles/colors';
@use '../../styles/mixins';

.experiment-banner {
  background-color: colors.$light-green;
  padding: 12px 0;
  text-align: center;
  font-size: 14px;
  font-weight: 600;

  @include mixins.lg {
    font-size: 16px;
  }

  .close-button {
    float: right;
    margin-left: 12px;
  }
}
