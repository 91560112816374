@use '../../../../../../../../../styles/colors';

.add-team-member-section {
  padding-top: 26px;

  .dialog-input-wrapper {
    display: unset !important;
  }

  ~ .add-team-member-button {
    color: colors.$blue;
    padding-top: 10px;
  }
}
