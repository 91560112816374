.title-and-leader-image {
  width: 100%;
  display: flex;
  flex-direction: column;

  @include md {
    flex-direction: row;
    justify-content: space-between;
    height: 450px;
  }
}

.title-text {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  @include md {
    margin-right: 50px;
    max-width: 60%;
  }
}

.title-text > p {
  margin-top: 0;
  @include md {
    margin: 15px 0;
    font-size: 18px;
  }
  @include xxl {
    font-size: 20px;
  }
}

.title-text > h1 {
  font-size: 24px;
  padding: 32px 0 24px 0;
  font-weight: 800;
  color: #111111;
  @include md {
    font-size: 40px;
  }
  @include xxl {
    font-size: 50px;
  }
}

.title-image {
  width: 270px;
  margin: 0 auto 55px auto;
  @include md {
    width: 30%;
    margin: 0;
  }
}

.browse-orgs {
  background-color: $background-gray;
  width: 100vw;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  position: relative;
  padding: 24px 20px 65px 20px;
  text-align: center;
  @include md {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 80px;
  }
}

.browse-orgs > h2 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
  @include md {
    font-size: 34px;
  }
}

.browse-orgs > img {
  margin-bottom: 30px;
}

.call-to-action {
  width: 100%;
  display: flex;
  flex-direction: column;
  img {
    padding-bottom: 3%;
    padding-top: 35px;
    order: 3;
    @include md {
      padding-top: 0;
      order: unset;
    }
  }
  @include md {
    flex-direction: row;
    justify-content: center;
  }
  @include lg {
    height: 450px;
  }
}

.bottom-color-bar {
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  height: 17px;
  background-color: #d2b356;
}

.company-name {
  font-weight: 700;
  @extend h6;
}

.call-to-action-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  order: 1;
  @include md {
    order: unset;
    align-items: flex-start;
  }
}

.browse-exhortation,
.call-to-action-text > h2 {
  font-size: 24px;
  font-weight: 700;
  color: #111111;

  padding: 32px 0;
  @include md {
    font-size: 34px;
    padding: 14px 0;
    margin-bottom: 15px;
  }
}

.call-to-action-text > img {
  margin-bottom: 1rem;
}

.call-to-action-text > div {
  font-size: 1rem;
  margin-bottom: 4rem;
}

.org-list {
  margin: auto;
  text-align: left;
  @include md {
    flex-direction: row;
    flex-wrap: wrap;
    display: flex;
    padding-top: 20px;
  }
}

.org-card {
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  @include md {
    padding-right: 50px;
    padding-left: 50px;
    width: 33%;
  }
  @include xl {
    width: 25%;
  }

  svg {
    color: #0976b5;
    margin-right: 16px;
    margin-bottom: 10px;
    width: 2rem;
  }
}

.org-card-header {
  @extend h4;
  display: inline-block;
  font-size: 22px;
  line-height: 2rem;
  text-decoration: none;

  color: $black;

  @include md {
    font-size: 24px;
    padding-left: 0;
  }

  &:hover {
    color: $black;
    text-decoration: underline;
  }
}
