.nav-dropdown-container.help-bar {
  width: 342px;
  right: 0;
  z-index: 10;

  .nav-dropdown-content {
    display: block;

    .nav-link {
      width: 250px;
      margin-left: auto;
      margin-right: auto;

      .nav-link-icon {
        width: 28px;
      }
    }
  }
}
