@use './../../styles/colors';

.gallery-view-button {
  color: colors.$gray;
  font-size: 18px;
  padding-left: 16px;

  &:hover {
    filter: drop-shadow(0px 4px 4px #00000090);
  }
}

.gallery-modal {
  max-width: 100%;
}
