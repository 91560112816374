.modal-contents-template {
  .modal-contents-template-body {
    display: flex;
    flex-direction: column;
    align-items: center;

    .modal-contents-template-title {
      text-transform: uppercase;
    }

    .modal-contents-template-text {
      text-align: center;
      margin: 2rem 0;
    }
  }
  .modal-contents-template-footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 2rem;

    button {
      min-width: 8.75rem;
    }
  }
}
