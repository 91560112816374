@use '../../styles/colors';

.error-container {
  align-items: center;
  display: flex;
  flex-direction: row;
  margin: auto;
  max-width: 1020px;
  justify-content: space-around;

  .error-content {
    flex: 0 1 40%;
    font-family: 'Open Sans';
    font-style: normal;
    color: colors.$black;
    padding: 90px 0 90px;

    span {
      display: flex;
      flex-direction: column;
    }

    .error-title {
      font-weight: 800;
      font-size: 40px;
      padding-bottom: 24px;
    }

    .error-text {
      font-size: 18px;
      line-height: 20px;
      a {
        display: contents;
        color: colors.$blue;
        text-decoration: underline;
      }
    }

    .refresh {
      padding-top: 30px;
      align-items: baseline;
      padding-bottom: 77px;

      button {
        background: #ffffff;
        border: 3px solid colors.$black;
        box-sizing: border-box;
        width: 158px;
        height: 38px;
        font-weight: bold;
        font-size: 14px;
        line-height: 16px;
        color: colors.$black;
        text-decoration: none;
      }
    }

    .support-links {
      font-size: 18px;
      line-height: 20px;

      a {
        color: colors.$blue;
        text-decoration: underline;
      }
    }
  }
}
