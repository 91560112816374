@use '../../../../../styles/colors';

.product-line-group {
  margin-bottom: 30px;
  text-align: center;
  background: colors.$background-gray;
  border-radius: 10px;
  position: relative;

  &:last-of-type {
    margin-bottom: 80px;
  }

  .product-group {
    color: colors.$white;
    background-color: colors.$blue;
    border-radius: 10px 0 0 10px;
    text-transform: uppercase;
    font-weight: bold;
    padding: 10px;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.1em;
    display: flex;
    justify-content: center;
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    width: 100px;

    .display-name {
      transform: rotate(180deg);
      text-align: center;
      max-height: 400px;
      height: 100%;
      margin: auto;
      writing-mode: vertical-rl;
    }
  }
}
