@use '../../../../styles/colors';

.delete-product-modal {
  text-align: center;

  .delete-product-title {
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0.1em;
    text-align: center;
  }

  .delete-product-paragraph {
    margin: 35px 0;
  }

  .checkbox-container {
    display: flex;
    justify-content: center;
    margin-bottom: 35px;

    .checkbox-input {
      width: 18px;
      height: 18px;
    }

    .checkbox-label {
      display: flex;
      align-items: center;
      margin-left: 1rem;
    }
  }

  .button-container {
    display: flex;
    justify-content: center;
    gap: 2rem;

    * {
      width: 8.8rem;
    }

    .delete-product-submit-btn {
      &:disabled {
        pointer-events: none;
        cursor: initial;
        opacity: 0.1;
      }
    }
  }
}
