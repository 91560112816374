.content {
  width: 100%;
  max-width: 23.32rem;

  .textField {
    max-width: 23.419rem;
    width: 100%;
    font: inherit;
  }

  .groupDropdown {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-block: 1.5rem;
  }
}
