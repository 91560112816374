@use 'styles/colors';

.productCard {
  display: block;
  width: 248px;
  min-height: 75px;
  background-color: colors.$white;
  border-radius: 12px;
  box-shadow: 0 10px 23px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease-in-out;
  margin-bottom: 10px;
  margin-top: 10px;
  text-decoration: none;
  text-align: left;
  padding: 16px;

  &:hover,
  &:focus {
    transform: scale(1.1);
    color: colors.$footer;
    box-shadow: none;
  }
}

.cardName {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  color: colors.$black;
  font-weight: 600;
  font-size: 14px;
  text-decoration: none;
  text-align: left;

  a:hover &,
  a:focus & {
    text-decoration: underline;
  }
}

.cardDate {
  margin-top: 22px;
  display: flex;
  flex-direction: row;
  align-items: center;

  .cardDateIcon {
    margin-right: 4px;
  }

  .updateTimestamp {
    padding: unset;
    font-size: 10px;
    font-weight: 700;
    color: rgba(89, 89, 89, 0.89);
  }
}

.cardStage {
  color: colors.$black;
  background: #c7dffe;
  border-radius: 10px;
  padding: 4px 8px;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  display: inline-block;
  margin-top: 22px;

  &.stageColor0 {
    background-color: #c7dffe;
  }

  &.stageColor1 {
    background-color: #f9e095;
  }

  &.stageColor2 {
    background-color: #fbccbc;
  }

  &.stageColor3 {
    background-color: #b2de77;
  }
}
