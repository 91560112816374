button:focus {
  box-shadow: 0 0 6px 3px #74a9e7;
}

#modal-delete-item {
  .delete-button-container {
    display: flex;
    justify-content: space-around;
    button {
      width: 125px;
    }
  }
}

#modal-action-message {
  .modal-button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    margin-top: 15px;
    button {
      margin-top: 15px;
      width: 225px;
    }
  }
}
