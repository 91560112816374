/*------------------------------------------------------------------------------------------*/
/* Print styles for Updates page */
/*------------------------------------------------------------------------------------------*/
@media print {
  nav,
  .product-content-left,
  .react-tabs__tab-list,
  .update-buttons,
  .update-arrows,
  .product-nav,
  footer,
  .forward-arrow-tooltip,
  .back-arrow-tooltip,
  .product-container-flex .tab-select-form,
  .gallery-view-button {
    display: none !important;
  }

  .tab-content-container {
    padding: 0;
  }

  .sticky {
    position: relative;
  }

  .product-header {
    padding: 0;
  }

  .product-container-flex {
    padding: 0;
    margin-top: 0;
  }

  .update-container-flex .update-box-wrapper .update-box-content {
    overflow-x: visible;
  }

  .content-container {
    padding: 0;
    flex: none;
    width: 100%;
    .product-content-right {
      width: 100%;
    }
    .update-container-flex {
      margin-top: -2mm;
      font-size: 70%;
      display: block;
      flex: none;
      width: 100%;
      max-width: 100%;
      .update-box-wrapper {
        display: block;
        flex: none;
        width: 100%;
        max-width: 100%;
        page-break-inside: avoid;
      }
    }
  }
}
