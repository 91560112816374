@import '../../../../../styles/base';

.org-actions-menu {
  a {
    text-decoration: none;
  }

  .button-org-action-item {
    @extend .button-white;

    width: 230px;
    box-shadow: none;
    margin-top: 0;
    background-color: transparent;

    &:hover,
    &:active,
    &:focus {
      background-color: transparent;
    }

    .org-actions-icon {
      margin-right: 10px;
    }
  }

  .org-action-menu-item {
    padding: 0 !important;
    margin-top: -3px !important;
  }
}
