@use '../../../styles/colors';

.product-hierarchy {
  .product-hierarchy-tooltip {
    top: 43%;
    transform: translateY(-50%);

    &:hover ~ .kanban-info-image {
      display: block;
      z-index: 2;
    }
  }

  .kanban-info-image {
    display: none;
    position: absolute;
    border-radius: 10px;
    top: 50%;
    transform: translateY(-50%);
    box-shadow: 0 6px 12px -7px rgba(0, 0, 0, 0.43);
    width: 370px;

    -moz-animation: showHide 0.15s ease-in; /* Firefox */
    -webkit-animation: showHide 0.15s ease-in; /* Safari and Chrome */
    -ms-animation: showHide 0.15s ease-in; /* IE10 */
    -o-animation: showHide 0.15s ease-in; /* Opera */
    animation: showHide 0.15s ease-in;
  }
}

@-webkit-keyframes showHide {
  /* Chrome, Safari */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-moz-keyframes showHide {
  /* FF */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-ms-keyframes showHide {
  /* IE10 */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-o-keyframes showHide {
  /* Opera */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes showHide {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
