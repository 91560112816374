@use '../../../../../styles/mixins';

.active-products-tab {
  .active-product-name {
    font-weight: 800;
  }

  .product-description {
    margin-top: 5px;

    @include mixins.tinyMceContentBulletFix;
  }
}
