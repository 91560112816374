.content-box {
  background-color: $white;
  border-radius: 10px;
  margin: 25px 0;
  padding: 25px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  img {
    max-width: 100%;
    height: auto;
  }
  .tox {
    width: 100%;
  }
  .save {
    margin: 15px auto 0 auto;
  }
  .content {
    overflow-x: auto;
    width: 100%;
    font-size: 15px;
    color: $black;
    margin: 0;
    position: relative;
    .kanban-image {
      width: 100%;
      display: block;
      margin: auto;
      @include md {
        width: 75%;
      }
    }
    @include tinyMceContentBulletFix;
  }

  .stage-name-mobile {
    display: inline-block;
    text-transform: capitalize;
    color: $green;
    font-size: 16px;
    font-weight: 600;
  }
  &:first-child {
    margin-top: 0;
  }
}

.archive-button-container {
  text-align: right;
}

.error-box {
  border-left: 11px solid #c72020;
  color: #cb0000;
  background-color: #fee8e8;
  padding: 8px;
  margin-top: 30px;
  svg {
    margin-right: 10px;
  }
}
.kanban-stage-container {
  padding-bottom: 25px;
  .stage {
    display: inline-block;
    &.stage-3,
    &.stage-4 {
      position: absolute;
      transform: translateX(-50%);
    }
  }

  .stage-3,
  .stage-4 {
    &:first-of-type {
      left: 12.5%;
    }
    &:last-of-type {
      left: 87.5%;
    }
  }
  .stage-3 {
    &:nth-of-type(2) {
      left: 50%;
    }
  }
  .stage-4 {
    &:nth-of-type(2) {
      left: 37.5%;
    }
    &:nth-of-type(3) {
      left: 62.5%;
    }
  }
}
