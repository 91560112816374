@use './../../../../../../styles/colors';
@use './../../../../../../styles/mixins';

.custom-toolbar {
  display: flex;
  flex-direction: row;
  padding-bottom: 20px;
  .toolbar-title {
    text-transform: uppercase;
    text-align: center;
    flex-grow: 1;
    font-size: 16px;
    font-weight: bold;
  }
  .close-button {
    color: colors.$gray;
    width: 12px;
    height: 12px;
  }
}
.calendar-icon {
  color: colors.$blue;
  width: 23px !important;
  height: 26px;
  &.disabled {
    color: colors.$disabled-gray;
  }
}
.dialog-date {
  .MuiInput-underline {
    &:before {
      border-bottom: 1.5px solid colors.$black;
    }
    &:after {
      border-bottom: 1.5px solid colors.$black !important;
    }
    &:hover:not(.Mui-disabled):before {
      border-bottom: 1.5px solid colors.$hover-blue;
    }
  }
  .MuiInputBase-root {
    max-width: 280px;
    cursor: pointer;
    font-weight: bold;
    font-size: 20px;
    &.invalid {
      border-color: colors.$red;
    }
    &:hover:not(.Mui-disabled) {
      color: colors.$hover-blue;
      .calendar-icon {
        color: colors.$hover-blue;
      }
    }
  }
  .MuiInputBase-input {
    &:not(.Mui-disabled) {
      cursor: pointer;
    }
    &.invalid {
      border-color: colors.$red;
    }
  }
}

#left-arrow:disabled,
#right-arrow:disabled {
  .MuiSvgIcon-root {
    color: colors.$disabled-gray;
  }
}
