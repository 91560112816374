@use '../../../../../../styles/mixins';

.content {
  width: 100%;
  margin-block: 2.5rem;

  @include mixins.sm {
    width: 22rem;
  }

  .textField {
    max-width: 23.419rem;
    width: 100%;
    font: inherit;
  }
}
